require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.match");

require("core-js/modules/es.string.split");

require("core-js/modules/es.typed-array.uint8-array");

require("core-js/modules/es.typed-array.copy-within");

require("core-js/modules/es.typed-array.every");

require("core-js/modules/es.typed-array.fill");

require("core-js/modules/es.typed-array.filter");

require("core-js/modules/es.typed-array.find");

require("core-js/modules/es.typed-array.find-index");

require("core-js/modules/es.typed-array.for-each");

require("core-js/modules/es.typed-array.includes");

require("core-js/modules/es.typed-array.index-of");

require("core-js/modules/es.typed-array.iterator");

require("core-js/modules/es.typed-array.join");

require("core-js/modules/es.typed-array.last-index-of");

require("core-js/modules/es.typed-array.map");

require("core-js/modules/es.typed-array.reduce");

require("core-js/modules/es.typed-array.reduce-right");

require("core-js/modules/es.typed-array.reverse");

require("core-js/modules/es.typed-array.set");

require("core-js/modules/es.typed-array.slice");

require("core-js/modules/es.typed-array.some");

require("core-js/modules/es.typed-array.sort");

require("core-js/modules/es.typed-array.subarray");

require("core-js/modules/es.typed-array.to-locale-string");

require("core-js/modules/es.typed-array.to-string");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.compressImage = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertBase64UrlToBlob = convertBase64UrlToBlob;
  _exports.compressImage = compressImage;

  // 将base64转换为blob
  function convertBase64UrlToBlob(urlData) {
    var arr = urlData.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], {
      type: mime
    });
  } // 压缩图片


  function compressImage(path, config) {
    return new Promise(function (resolve) {
      var img = new Image();
      img.src = path;

      img.onload = function () {
        var that = this;
        var w = that.width;
        var h = that.height;
        var scale = w / h;
        w = config.width || config.height * scale;
        h = config.height || config.width / scale;
        var quality = 0.7; // 默认图片质量为0.7
        // 生成canvas

        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d'); // 创建属性节点

        var anw = document.createAttribute('width');
        anw.nodeValue = w;
        var anh = document.createAttribute('height');
        anh.nodeValue = h;
        canvas.setAttributeNode(anw);
        canvas.setAttributeNode(anh);
        ctx.drawImage(that, 0, 0, w, h);

        if (config.quality && config.quality <= 1 && config.quality > 0) {
          quality = config.quality;
        }

        var base64 = canvas.toDataURL('image/*', quality); // var blob = convertBase64UrlToBlob(base64)
        // 回调函数返回base64的值，也可根据自己的需求返回blob的值

        resolve(base64);
      };
    });
  }
});